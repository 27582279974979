import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { select, Store, Action } from '@ngrx/store';
import { filter, switchMap, tap } from 'rxjs';

import { AuthUser } from './auth.models';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */

  constructor(
    private readonly store: Store,
    private readonly http: HttpClient,
  ) {
    // this.auth.isAuthenticated$
    //   .pipe(
    //     tap((v) => {
    //       console.log('isAuthent', v);
    //     })
    //   )
    //   .subscribe((val) => {
    //     console.log('is authenticated', val)
    //   })
  }

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  getSelf() {
    return this.http.get<AuthUser>('/api/user');
  }

  getSelfResources() {
    return this.http.get<{ resources: Array<string> }>('/api/user/resources');
  }
}
