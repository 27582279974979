import _ from 'lodash'
import { Pipe, PipeTransform } from '@angular/core';
import { NotarySession, NotarySessionUser, NotarySessionUserType } from '@ui/view-models';
import { parseFullName } from '../parse-full-name';

/*
* Gets the full name of the particiant given a notary session
*/
@Pipe({
  name: 'fullName',
  pure: true,
})
export class ToFullNamePipe implements PipeTransform {
  transform(value: NotarySession, type: NotarySessionUserType = 'consumer'): string {

    const found = _.find(value.notarySessionUsers, (el) => {
      return el.type === type
    });

    if (found) {
      return parseFullName(found);
    }
    return '-'
  }
}
