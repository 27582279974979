import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { select, Store, Action } from '@ngrx/store';
import { filter, Observable, switchMap, tap } from 'rxjs';

import * as AuthActions from './auth.actions';
import { AuthUser } from './auth.models';
import * as AuthFeature from './auth.reducer';
import * as AuthSelectors from './auth.selectors';
import { AuthUserService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  user$ = this.store
    .pipe(select(AuthSelectors.getAuthUser))
    .pipe(
      filter((user) => user !== null && user !== undefined),
    ) as Observable<AuthUser>

  constructor(
    private readonly store: Store,
    private readonly authUser: AuthUserService,
  ) {
    // this.auth.isAuthenticated$
    //   .pipe(
    //     tap((v) => {
    //       console.log('isAuthent', v);
    //     })
    //   )
    //   .subscribe((val) => {
    //     console.log('is authenticated', val)
    //   })
  }

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(AuthActions.initAuth());
    this.store.dispatch(AuthActions.initAuthPermissions());
  }
}
