import { NotarySessionUser } from '.';
import { Doc } from './doc.model';
import { Request } from './request.model';


export type NotarySessionType = 'rin' | 'ron' | 'ipen';

export interface NotarySession extends Record<string, any> {
  id: string;
  type: NotarySessionType;
  notaryId?: string;
  notarizationType: string;
  organizationId: string;
  notarySessionUsers: Array<NotarySessionUser>;
  requestId: string;
  request: Request;
  senderId: string | null;
  enoteId: string | null;
  status: string;
  roomId: string;
  docs: Array<Doc>
  recordingSid?: string;
  completedAt?: string;
  createdAt: Date;
  taggingOrganizationId?: string;
  sessionDescription?: string;
  senderFirstName: string;
  senderLastName: string;
  assignedToEmail: string;
  assignedToOrgName: string;
  updatedAt: Date;
}
