import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ContextAuthHttpService } from "./context-auth-http.service";
import { ContextAuthHttpInterceptor } from './context-auth-http.interceptor'
import { ContextAuthGuard } from "./context-auth.guard";

@NgModule({
  providers: [],
})
export class ContextAuthModule {
  static forRoot(): ModuleWithProviders<ContextAuthModule> {
    return {
      ngModule: ContextAuthModule,

      providers: [
        ContextAuthHttpService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ContextAuthHttpInterceptor,
          multi: true,
        },
        {
          provide: ContextAuthGuard,
          useClass: ContextAuthGuard
        }
      ],
    };
  }
}
