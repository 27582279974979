import { NotarySessionUser } from '.';



export interface Transaction extends Record<string, any> {
  notarySessionUsers: Array<NotarySessionUser>;
  requestId: string;
  requestGroupId: string;
  nsId: string;
  senderId: string;
  senderEmail: string;
  status: string;
  roomId: string;
  recordingSid: string;
  completedAt: string;
  createdAt: string;
  senderFirstName: string;
  senderLastName: string;
  assignedToEmail: string;
  assignedToOrgName: string;
}
