import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { isEmpty, isNil } from 'lodash';
import { Observable, switchMap } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ContextAuthHttpService {
  context?: string;
  constructor(
    private route: ActivatedRoute,
  ) { }

  isAuthenticated$: Observable<boolean> = this.route.queryParams
    .pipe(
      switchMap(async (params) => {
        const context = params['context'];
        return !(isEmpty(context) || isNil(context)) || !(isEmpty(this.context) || isNil(this.context));
      })
    )


  setContext(val: string) {
    this.context = val;
  }

  getTokenSilently(options: any) {
    return this.route.queryParams
      .pipe(switchMap(async (params) => {
        return (params['context'] as string) || this.context;
      }));
  }
}
