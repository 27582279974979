import { User } from "./user.model";

export type NotarySessionUserType = 'consumer' | 'signer' | 'witness';
export interface NotarySessionUser extends Partial<Omit<User, 'email'>> {
  id: string;
  type: NotarySessionUserType;
  notarySessionId: string;
  kbaPassed?: boolean;
  kbaRequired?: boolean;
  kbaAttempts?: number;
  authUserAccessCode?: number;
  credAnalysisRequired?: boolean;
  documentVerified?: boolean;
  kbaProvider?: string;
  kbaScore?: string;
  docVerifyIdType?: string;
  failureReason?: string;
  createdAt: Date;
  updatedAt: Date;
  user: User;
}
