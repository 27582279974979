import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import moment from 'moment';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({
  name: 'timeSince',
  pure: true,
})
export class TimeSincePipe implements PipeTransform {
  transform(value: string | Date, time: 'days' | 'hours' | 'minutes' | 'seconds' = 'minutes'): string {
    if (moment(value).isValid()) {
      const duration = moment.duration(moment().diff(value));


      if (duration[time] && _.isFunction(duration[time])) {
        return `${duration[time]()} ${time}`
      }
      return `${duration.minutes()} minutes`;
    }
    return '-'
  }
}
