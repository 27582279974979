import { ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgrxCoreModule } from '@ngrx/core';
import { HttpClientModule } from '@angular/common/http';
import { GlobalErrorHandler, ErrorsModule, ContextAuthModule } from '@app/shared';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgrxAuthModule } from '@ngrx/auth';


  @NgModule({
    declarations: [AppComponent],
    imports: [
      AppRoutingModule,
      BrowserModule,
      BrowserAnimationsModule,
      MatMomentDateModule,
      ContextAuthModule.forRoot(),
      ErrorsModule,
      HttpClientModule,
      MatSnackBarModule,

      // ngrx redux
      NgrxCoreModule,
      NgrxAuthModule,
    ],
    providers: [
      {
        provide: ErrorHandler,
        useClass: GlobalErrorHandler,
      },
      {
        provide: LocationStrategy,
        useClass: PathLocationStrategy,
      },
    ],
    bootstrap: [AppComponent],
  })
  export class AppModule { }
