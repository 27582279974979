export interface UnderwriterSetting {
  id: string;
  underwriterId: string;
  key: Key;
  value: null | string;
}

export enum Key {
  IdvCA = "IDV:CA",
  IdvFaceRec = "IDV:FACE_REC",
  IdvKba = "IDV:KBA",
  IdvPersonallyKnown = "IDV:PERSONALLY_KNOWN",
  IdvSecondaryID = "IDV:SECONDARY_ID",
  SessionRestoreTimeout = "SESSION_RESTORE_TIMEOUT",
}
