import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { map, pluck } from 'rxjs/operators';
import {
  A0Organization,
  Organization,
  FeatureMapping,
  Role,
  User,
  RecurlyResponse,
  Coupon,
  CouponPayload,
  Account,
  Invoice,
  InvoicePayload,
  Resource,
  NotarySession,
  Transaction,
} from '@ui/view-models';
import * as _ from 'lodash';
import qs from 'qs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  sendPasswordResetEmail(orgId: any, userId: string) {
    return this.http.post(`/api/organization/${orgId}/users/${userId}/password-change-email`, {});
  }
  constructor(private http: HttpClient, private auth: AuthService) {}

  composeVideo(nsId: string) {
    return this.http.post<{ message: string }>(
      `/api/compose-video/${nsId}`,
      {}
    );
  }

  getA0Orgs() {
    return this.http
      .get<{ data: A0Organization[] }>('/api/a0-orgs')
      .pipe(pluck('data'));
  }

  getOrgs(startRow: number, limit: number, opts: any) {
    const params = qs.stringify({
      skip: startRow,
      limit,
      ...opts,
    });
    return this.http.get<{ raw: Organization[]; total: number }>(
      `/api/getorganizations?${params}`,
      {}
    );
  }
  uploadFile(url: string, file: File) {
    return this.http.put(url, file, {
      headers: { 'Content-Type': 'application/pdf' },
    });
  }

  getDefaultResources(startRow: number, limit: number, opts: any) {
    const params = qs.stringify({
      skip: startRow,
      limit,
      ...opts,
    });
    return this.http.get<{ data: Resource[] }>(
      `/api/default-resources?${params}`
    );
  }

  getDefaultRoles(startRow: number, limit: number, opts: any) {
    const params = qs.stringify({
      skip: startRow,
      limit,
      ...opts,
    });
    return this.http.get<{ data: Role[]; total: number }>(
      `/api/default-roles?${params}`
    );
  }

  getRoles(startRow: number, limit: number, opts: any) {
    const params = qs.stringify({
      skip: startRow,
      limit,
      ...opts,
    });
    return this.http.get<{ data: Role[]; total: number }>(
      `/api/roles?${params}`
    );
  }

  createDefaultRoleResource(roleId: string, resourceId: string) {
    return this.http.post<{ data: Role }>(`/api/role-resource`, {
      roleId,
      resourceId,
    });
  }

  deleteDefaultRoleResource(roleId: string, resourceId: string) {
    return this.http.delete<{ count: number }>(`/api/role-resource`, {
      body: {
        roleId,
        resourceId,
      },
    });
  }

  getNotarySessions(startRow: number, limit: number, opts: any) {
    const params = qs.stringify({
      skip: startRow,
      limit,
      ...opts,
    });
    return this.http.get<{ data: NotarySession[]; total: number }>(
      `/api/notary-sessions?${params}`,
      {}
    );
  }

  getUsersByOrgId(orgId: string) {
    return this.http.get<User[]>('/api/searchusersbyorgid', {
      params: {
        orgId,
      },
    });
  }

  getUsers(offset = 0, limit = 50, filter = {}, order = {}) {
    const p = {
      offset,
      limit,
      filter,
      ...order,
    };

    const params = qs.stringify(p);
    return this.http
      .get<{ raw: User[]; total: number }>(`/api/users?${params}`)
      .pipe(
        map(({ raw, total }) => ({
          data: raw,
          total,
        }))
      );
  }

  resetUserPassword(userId: string, newPassword: string) {
    return this.http.post('/api/resetpassword', {
      userId,
      password: newPassword,
    });
  }

  getIndependentNotaries(offset = 0, limit = 20, sort = {}) {
    return this.http
      .get<{ data: Account[]; total: number }>(
        `/api/recurly/independent-notaries?${qs.stringify({
          limit,
          offset,
          ...sort,
        })}`,
        {}
      )
      .pipe(
        map(({ data, total }) => {
          const d = _.map(data, (data) => {
            return {
              ...data,
              createdAt: data.createdAt && new Date(data.createdAt),
              updatedAt: data.updatedAt && new Date(data.updatedAt),
            };
          });
          return {
            data: d,
            total,
          };
        })
      );
  }

  exportIndependentNotaries() {
    return this.http.get(`/api/recurly/independent-notaries/export`, {
      responseType: 'text',
    });
  }

  createIndependentNotary(recurly_id: string) {
    return this.http
      .post<{ data: RecurlyResponse }>('/api/recurly/independent-notary', {
        recurly_id,
      })
      .pipe(pluck('data'));
  }

  getRecentTransactions() {
    return this.http
      .get<{ raw: Array<Transaction> }>('/api/gettransactions')
      .pipe(pluck('raw'))
      .pipe(
        map((d) => {
          return _.map(
            d,
            (obj) =>
              ({
                ...obj,
                nsId: _.get(obj, 'notarySessions.id'),
                senderId: _.get(obj, 'sender.id'),
                senderEmail: _.get(obj, 'sender.email'),
                status: _.get(obj, 'notarySessions.status'),
                roomSid: _.get(obj, 'notarySessions.roomSid'),
                recordingSid: _.get(obj, 'notarySessions.recordingSid'),
                completedAt: _.get(obj, 'notarySessions.completedAt'),
                createdAt: _.get(obj, 'notarySessions.createdAt'),
                referralId: _.get(obj, 'notarySessions.referralId'),
                senderFirstName: _.get(obj, 'sender.firstName'),
                senderLastName: _.get(obj, 'sender.lastName'),
                assignedToEmail: _.get(obj, 'assigned.email'),
                assignedToOrgName: _.get(obj, 'assignedOrg.name'),
              } as Transaction)
          );
        })
      );
  }

  syncAuth0Org(id: string, org: any) {
    return this.http.post<Organization>(
      `/api/organization/${id}/connection-id`,
      org
    );
  }

  getCoupons() {
    return this.http
      .get<{ raw: Coupon[] }>('/api/getcoupons')
      .pipe(pluck('raw'));
  }
  getInvoices() {
    return this.http
      .get<{ data: Invoice[] }>('/api/invoices/all')
      .pipe(pluck('data'));
  }
  createInvoice(payload: InvoicePayload) {
    return this.http
      .post<{ data: Invoice }>('/api/invoice', payload)
      .pipe(pluck('data'));
  }
  voidInvoice(id: string) {
    return this.http.post<Invoice>(`/api/invoice/void/${id}`, {});
  }

  createCoupon(payload: CouponPayload) {
    return this.http
      .post<{ data: Coupon }>('/api/newcoupon', payload)
      .pipe(pluck('data'));
  }

  getTransactionsByEmail(email: string) {
    return this.http
      .get<{ data: Array<NotarySession> }>('/api/gettransactionsbyemail', {
        params: { email },
      })
      .pipe(pluck('data'))
      .pipe(
        map((d) => {
          return _.map<NotarySession, Transaction>(
            d,
            (obj) =>
              ({
                ...obj,
                id: _.get(obj, 'request.id'),
                requestGroupId: _.get(obj, 'request.requestGroupId'),
                nsId: _.get(obj, 'id'),
                senderId: _.get(obj, 'request.sender.id'),
                senderEmail: _.get(obj, 'request.sender.email'),
                status: _.get(obj, 'status'),
                roomSid: _.get(obj, 'roomSid'),
                completedAt: _.get(obj, 'completedAt'),
                createdAt: _.get(obj, 'createdAt'),
                senderFirstName: _.get(obj, 'request.sender.firstName'),
                senderLastName: _.get(obj, 'request.sender.lastName'),
                assignedToEmail: _.get(obj, 'request.assigned.email'),
                assignedToOrgName: _.get(obj, 'request.assignedOrg.name'),
              } as unknown as Transaction)
          );
        })
      );
  }

  getTransactionsById(id: string) {
    return this.http
      .get<{ data: NotarySession }>(`/api/gettransaction/${id}`)
      .pipe(pluck('data'))
      .pipe(
        map((obj) => {
          return {
            ...obj,
            id: _.get(obj, 'request.id'),
            requestGroupId: _.get(obj, 'request.requestGroupId'),
            nsId: _.get(obj, 'id'),
            senderId: _.get(obj, 'request.sender.id'),
            senderEmail: _.get(obj, 'request.sender.email'),
            status: _.get(obj, 'status'),
            roomSid: _.get(obj, 'roomSid'),
            completedAt: _.get(obj, 'completedAt'),
            createdAt: _.get(obj, 'createdAt'),
            senderFirstName: _.get(obj, 'request.sender.firstName'),
            senderLastName: _.get(obj, 'request.sender.lastName'),
            assignedToEmail: _.get(obj, 'request.assigned.email'),
            assignedToOrgName: _.get(obj, 'request.assignedOrg.name'),
          } as unknown as Transaction;
        })
      );
  }

  createOrganization(payload: Record<string, string>) {
    return this.http.post<{ organization: Organization; admin: User }>(
      `/api/organization`,
      payload
    );
  }
  createUser(orgId: string, payload: Record<string, string>) {
    return this.http.post<{ organization: Organization; admin: User }>(
      `/api/organization/${orgId}/users`,
      payload
    );
  }


  setOrgAccessToken(id: string, value?: string) {
    return this.http.post<Organization>(
      `/api/organization/${id}/access-token`,
      { value }
    );
  }

  getNotaryCounties(id: string) {
    return this.http.get<Array<Record<string, string>>>(
      `/api/counties?userId=${id}`,
      {}
    );
  }

  getNotaryCountiesByState(commissionState: string) {
    return this.http.get<Array<Record<string, string>>>(
      `/api/counties/by-state?commissionState=${commissionState}`,
      {}
    );
  }

  getDocuments(id: string) {
    return this.http.get<Array<Record<string, string>>>(
      `/api/notary-session/${id}/documents`,
      {}
    );
  }

  getNotaryUserId(id: string) {
    return this.http.get<{ userId: string; from: string; user: User }>(
      `/api/notary-sessions/${id}/user_id`,
      {}
    );
  }
  getNotaryUserAccessToken(id: string, userId: string) {
    return this.http.get<{ token: string }>(
      `/api/notary-sessions/${id}/access_token?userId=${userId}`,
      {}
    );
  }

  getDocumentRevisions(id: string) {
    return this.http.get<Array<Record<string, string>>>(
      `/api/notary-session/${id}/document-revisions`,
      {}
    );
  }
  getDocumentRevisionsByDocId(id: string) {
    return this.http.get<Array<Record<string, string>>>(
      `/api/notary-session/documents/${id}/revisions`,
      {}
    );
  }

  createRevision(payload: {
    nsId: string;
    userId: string;
    requestId: string;
    docId: string;
    url: string;
  }) {
    return this.http.post<Record<string, string>>(`/api/revision`, payload);
  }

  completeNotaryRoom(payload: { nsId: string; userId: string }) {
    return this.http.post<Record<string, string>>(
      `/api/notary-session/${payload.nsId}/complete`,
      payload
    );
  }

  getUploadUrl(gcsRefId: string) {
    return this.http.post<{ url: string; gcsRefId: string }>(
      `/api/upload-url`,
      { gcsRefId }
    );
  }

  setUserRole(id: string, userId: string, roleId: string) {
    return this.http.patch<{ data: User }>(
      `/api/organization/${id}/user/${userId}/role`,
      {
        roleId,
      }
    );
  }

  sealPdf(gcsRefId: string, userId: string, location: string, flatten = false) {
    return this.http.post<{ url: string, gcsPath: string }>('/api/seal-pdf', {
      gcsRefId,
      userId,
      location,
      flatten,
    });
  }

  getFeatureMapping() {
    return this.http.get<FeatureMapping>(`/api/organization/feature-mapping`);
  }
  getOrgFeatureMapping(orgId: string) {
    return this.http.get<Record<string, Array<string>>>(
      `/api/organization/${orgId}/feature-mapping`
    );
  }
  getFeatureConfig(orgId: string) {
    return this.http.get<Record<string, boolean>>(
      `/api/organization/${orgId}/feature-access`
    );
  }
  updateFeatureConfig(orgId: string, payload: Record<string, boolean>) {
    return this.http.patch<Record<string, boolean>>(
      `/api/organization/${orgId}/feature-access`,
      payload
    );
  }
}
