import _ from 'lodash';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService, TemplatingService, OrganizationsService } from '.';
import { LogoComponent } from './components/logo/logo.component';
import { FeatherIconsModule } from './feather-icons.module';
import { MaterialModules } from './material.module';
interface ISharedModuleOptions {
  services: Array<'organization' | 'notary-session'>
}

@NgModule({
  declarations: [
    LogoComponent,
  ],
  imports: [
    CommonModule,
    ...MaterialModules,
    FeatherIconsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    ...MaterialModules,
    FeatherIconsModule,
    FormsModule,
    LogoComponent,
    ReactiveFormsModule,
  ],
  providers: [MessageService],
})
export class SharedModule {
  static forRoot(options: ISharedModuleOptions): ModuleWithProviders<SharedModule> {
    const providers = [];

    if (_.includes(options.services, 'templating')) {
      providers.push(TemplatingService);
    }
    if (_.includes(options.services, 'notary-session')) {
      providers.push(TemplatingService);
    }
    if (_.includes(options.services, 'organization')) {
      providers.push(OrganizationsService);
    }

    return {
      ngModule: SharedModule,
      providers
    }
  }
}
