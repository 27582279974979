import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, switchMap, tap } from "rxjs";
import { ContextAuthHttpService } from "./context-auth-http.service";



@Injectable({
  providedIn: 'root'
})
export class ContextAuthHttpInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ContextAuthHttpService) private auth0Client: ContextAuthHttpService,
    private route: ActivatedRoute,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return this.route.queryParams
      .pipe(
        switchMap((params) => {
          const context = params['context'] as string;

          // Clone the request and attach the bearer token
          const clone = context
            ? req.clone({
              headers: req.headers.set(
                'Authorization',
                `Bearer ${context}`
              ),
            })
            : req;

          return next.handle(clone);
        })
      )

  }
}
