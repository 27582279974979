import { load } from 'cheerio';

/**
 * Function used to extract the custom data from an annotations' xfdf.
 *
 * @param {string} annotXfdf Annotation xfdf string.
 * @returns {*} Parsed custom data.
 */
export const getAnnotCustomData = (annotXfdf: string) => {
  const $ = load(annotXfdf, { xmlMode: true });

  const customData = $('trn-custom-data');
  const bytes = customData.attr('bytes') as string;

  return JSON.parse(bytes);
}
