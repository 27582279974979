import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | keyBy:key
 * Example:
 *   {{ myArray | keyBy:id }}
 *   formats to: { [id]: ... }
*/
@Pipe({name: 'keyBy'})
export class KeyByPipe implements PipeTransform {
  transform<T>(value: T[], key: string) {
    return _.keyBy(value, key);
  }
}