import { createAction, props } from '@ngrx/store';
import { AuthUser } from './auth.models';

export const setAuthUser = createAction(
  '[Auth] Set User',
  props<AuthUser>()
);

export const initAuth = createAction('[Auth] Init');

// user
export const loadAuthSuccess = createAction(
  '[Auth/API] Load Auth Success',
  props<{ user?: AuthUser }>()
);
export const loadAuthFailure = createAction(
  '[Auth/API] Load Auth Failure',
  props<{ error: any }>()
);


// permissions
export const initAuthPermissions = createAction('[Auth] Init Permission Resources');
export const loadAuthPermissionsSuccess = createAction(
  '[Auth/API] Load Permissions Success',
  props<{ resources?: Array<string> }>()
);


export const loadAuthPermissionsFailure = createAction(
  '[Auth/API] Load Permissions Failure',
  props<{ error: any }>()
);
