import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Organization, NotarySession, NotarySessionUser, SigningOrder, User, Underwriter } from '@ui/view-models';
import qs from 'qs';
import { map, pluck } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';


export type GetRonDocumentsStatus = 'draft' | 'pending' | 'completed' | 'canceled' | 'incoming';

@Injectable({
  providedIn: 'root',
})
export class NotarySessionService {
  constructor(private http: HttpClient) {
  }

  getUsers(ids: string[] = []) {
    const q = ids.length > 0 ? qs.stringify({ ids }) : ''
    return this.http.get<{ count: number, data: Array<User> }>(`/api/organization/users?${q}`);
  }

  getOrgUsers(args: any) {
    const params = qs.stringify({
      ...args,
    });
    return this.http.get<{ count: number, data: Array<User> }>(`/api/organization/permissions/users?${params}`);
  }

  getForm(formName: string) {
    return this.http.get<FormlyFieldConfig[]>(`/api/forms/${formName}`);
  }


  getNotaries() {
    return this.http.get<{ count: number, notaries: Array<User> }>('/api/organization/notaries?accountActive=true');
  }

  getUnderwriters() {
    return this.http.get<{
      count: number,
      underwriters: Array<Underwriter>
    }>('/api/underwriter/settings')
  }


  searchNotarySessionsById(ids: Array<string>) {
    return this.http
      .get<{ total: number, data: Array<NotarySession>, count: number }>(`/api/notary-session?${qs.stringify({
        ids
      })}`, {})
      .pipe(pluck('data'))

  }

  getSigningOrders(params: any) {
    return this.http
      .get<{ total: number, data: Array<SigningOrder>, count: number }>(`/api/integration/signing-order?${qs.stringify({
        ...params,
      })}`, {})
  }


  getRonDocuments(params: any) {
    return this.http.get<{
      count: number;
      data: Array<NotarySession>
    }>(`/api/pending-notary-session?${qs.stringify(params)}`, {})
  }


  getTaggableTransactions(params: any = {}) {
    const q = qs.stringify(params);
    return this.http.get<{
      data: Array<{
        id: string;
        currentTaggerId: string;
        docs: Array<Document>
        participants: Array<NotarySessionUser>;
        sendingOrg: Organization;
      }>;
      total: number,
    }>(`/api/esign/taggable-transactions?${q}`)
  }

  getEsigns(params: any = {}) {
    const q = qs.stringify(params);
    return this.http.get<{
      data: Array<{
        id: string;
        currentTaggerId: string;
        docs: Array<Document>
        participants: Array<NotarySessionUser>;
        sendingOrg: Organization;
      }>;
      total: number,
    }>(`/api/esign?${q}`)
  }

  getNotaryQueuePending(params: any) {
    return this.http
      .get<{ data: NotarySession[], count: number }>(`/api/notary-queue/pending?${qs.stringify({
        ...params,
      })}`, {})
      .pipe(
        map(({ data, count }) => ({ data, total: count }))
      )
  }

  getMyQueue(params: any) {
    return this.http
      .get<{ data: NotarySession[], count: number }>(`/api/notary-queue/my-queue?${qs.stringify({
        ...params,
      })}`, {})
      .pipe(
        map(({ data, count }) => ({ data, total: count }))
      )
  }

  getTaggingQueue(params: any) {
    return this.http
      .get<{ data: NotarySession[], count: number }>(`/api/notary-queue/tagging-queue?${qs.stringify({
        ...params,
      })}`, {})
      .pipe(
        map(({ data, count }) => ({ data, total: count }))
      )
  }


  getAssignableNotaries(params: any) {
    return this.http
      .get<{ notaries: Array<any>, count: number }>(`/api/organization/notaries?${qs.stringify({
        ...params,
      })}`, {})
      .pipe(
        map(({ notaries, count }) => ({
          data: _.map(notaries, (n) => ({
            ...n,
            name: `${_.upperFirst(n.firstName)} ${_.upperFirst(n.lastName)}`,
          })),
          total: count
        }))
      )
  }

  lockSession(nsId: string) {
    return this.http
      .post<{ data: NotarySession[] }>(`/api/notary-queue/${nsId}/lock`, {});
  }

  assignNotary(nsId: string, notaryId: string) {
    return this.http
      .post<{ data: NotarySession[] }>(`/api/notary-queue/${nsId}/assign`, {
        userId: notaryId
      });
  }
}
