import _ from 'lodash';

/**
 * Function used to check if user has some of the required permissions.
 *
 * @param {string[]} permissions Array with all of the user permissions.
 * @param {string[]} requiredPermissions Array with the required permissions to check.
 * @returns {boolean} Whether or not the user has some of the required permissions.
 */
export const hasSomePermissions = (permissions: string[], requiredPermissions: string[]) => {
  return _.size(_.intersection(permissions, requiredPermissions)) > 0;
}

/**
 * Function used to check if user has all of the required permissions.
 *
 * @param {string[]} permissions Array with all of the user permissions.
 * @param {string[]} requiredPermissions Array with the required permissions to check.
 * @returns {boolean} Whether or not the user has all of the required permissions.
 */
export const hasAllPermissions = (permissions: string[], requiredPermissions: string[]) => {
  return requiredPermissions.every((p) => permissions.includes(p));
}
