import { enableProdMode } from '@angular/core';
import _ from 'lodash';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { connect } from '@rxjs-insights/devtools/connect';


if (environment.production) {
  enableProdMode();
} else {
  connect();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
