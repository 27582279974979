import { ErrorHandler, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import _ from 'lodash';
import { ErrorService } from './error.service';
import { GlobalErrorHandler } from './global-handler.interceptor';
import { LoggingService } from './logging.service';
import { NotificationService } from './notification.service';

@NgModule({
  imports: [
    MatSnackBarModule
  ],
  providers: [
    LoggingService,
    NotificationService,
    ErrorService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ]
})
export class ErrorsModule {
}
