
/**
 * Function used to convert a Hex string into a RGBA object. If not provided, the alpha value
 * defaults to 1. Doesn't work with hex shortcuts.
 *
 * @param {string} hex Hex string to convert
 * @param {number} [alpha=1] Alpha value to use. Defaults to `1`.
 * @returns {{ R: any; G: any; B: any; A: number; }} Object with values for R, G, B, and A.
 */
export const hexToRGBA = (hex: string, alpha = 1) => {
  let parseString = hex;

  if (hex.startsWith('#')) { parseString = hex.slice(1, 7); }

  if (parseString.length !== 6) { return null; }

  const R = parseInt(parseString.slice(0, 2), 16);
  const G = parseInt(parseString.slice(2, 4), 16);
  const B = parseInt(parseString.slice(4, 6), 16);

  if (isNaN(R) || isNaN(G) || isNaN(B)) {  return null; }

  return {
    R,
    G,
    B,
    A: alpha,
  };
};