import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';


/**
 * This pipe is used for sorting an array of objects.
 * It can sort in ascending or descending order.
 * It requires a `sortPath`, a string representing a dot separated path within the object to reach
 * the value we want to use for sorting. This allows to sort by shallow or deeply nested properties.
 */
@Pipe({ name: 'sortArrOfObj' })
export class SortArrayOfObjectsPipe implements PipeTransform {
  transform(array: any[], sortPath: string, order: 'ASC' | 'DESC' = 'ASC'): any[] {
    if (!array || !sortPath) {
      return array;
    }

    const sortedArray = _.sortBy(array, (obj) => _.get(obj, sortPath));

    if (order === 'DESC') {
      return sortedArray.reverse();
    }

    return sortedArray;
  }
}
