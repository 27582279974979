import { NotarySession, Organization, User } from '.';

export interface RequestGroup {
  id: string;
  orgPayment: boolean;
  updatedAt: Date;
  createdAt: Date;
}

export interface Request extends Record<string, any> {
  assigned?: User;
  assignedOrg?: Organization;
  assignedTo?: string;
  assignedToOrg?: string;
  id: string;
  sender?: User;
  senderId?: string;
  dueDate?: Date;
  isRealEstate: boolean;
  requestGroupId: string;
  requestGroup: RequestGroup;
  underwriterId: string;
  notarySessions: NotarySession[];
  createdAt: Date;
  updatedAt: Date;
}
