import _ from 'lodash';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, AUTH_FEATURE_KEY } from './auth.reducer';


export const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const getAuthUser = createSelector(
  getAuthState,
  (state: AuthState) => state.user
);
