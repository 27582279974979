export interface Notary {
  id: string;
  notaryStateId: string;
  comissionsState: string;
  bondId: string;
  bondExpirationDate: Date;
  dob: Date;
  userId: string;
  primaryCountyId?: string;
  secondaryCountyId?: string;
  tertiaryCountyId?: string;

  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  [key: string]: any;
}
