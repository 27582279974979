import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  CanActivateChild,
  Router,
} from '@angular/router';
import { ContextAuthHttpService } from './context-auth-http.service';
import { Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContextAuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(
    private auth: ContextAuthHttpService,
    private router: Router
  ) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    (window as any).auth0 = this.auth;
    return this.auth.isAuthenticated$.pipe(take(1));
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    (window as any).auth0 = this.auth;
    return this.redirectIfUnauthenticated(state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    (window as any).auth0 = this.auth;
    return this.redirectIfUnauthenticated(state);
  }

  private redirectIfUnauthenticated(
    state: RouterStateSnapshot
  ): Observable<boolean> {
    (window as any).auth0 = this.auth;
    return this.auth.isAuthenticated$.pipe(
      tap((loggedIn) => {
        if (!loggedIn) {
          // this.router.navigateByUrl('/auth/login', {
          //   state: { returnTo: state.url },
          // });
          // this.auth.loginWithRedirect({
          //   appState: { target: state.url },
          // });
        }
      })
    );
  }
}
