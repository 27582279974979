export interface Coupon extends Record<string, any> {
  code: string;
  createdAt: Date;
  discountAmount: number;
  discountPercentage: number;
  discountType: 'PERCENTAGE' | 'AMOUNT';
  invalidDate: Date;
  maxUses: number;
  maxUsesPerUser: number;
  organizationId: string;
  updatedAt: Date;
  validDate: Date;
}

export interface CouponPayload
  extends Pick<
    Coupon,
    | 'code'
    | 'discountAmount'
    | 'discountPercentage'
    | 'discountType'
    | 'validDate'
    | 'invalidDate'
  > {
  code: string;
  createdAt: Date;
  discountAmount: number;
  discountPercentage: number;
  discountType: 'PERCENTAGE' | 'AMOUNT';
  invalidDate: Date;
  maxUses: number;
  maxUsesPerUser: number;
  organizationId: string;
  updatedAt: Date;
  validDate: Date;
}
