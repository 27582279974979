import _ from 'lodash';
import { Action, createReducer, on } from '@ngrx/store';
import { loadAuthPermissionsSuccess, loadAuthSuccess, setAuthUser } from './auth.actions';
import { AuthUser } from './auth.models';


export interface AuthState {
  isAuthenticated: boolean;
  user?: AuthUser;
  resources: Array<string>
}

const initialState: Readonly<AuthState> = {
  isAuthenticated: false,
  resources: [],
};


export const AUTH_FEATURE_KEY = 'auth';

const reducer = createReducer(
  initialState,
  on(setAuthUser, (state, n: AuthUser) => ({
    ...state,
    user: n
  })),
  on(loadAuthSuccess, (state, n) => ({
    ...state,
    isAuthenticated: true,
    user: n.user
  })),
  on(loadAuthPermissionsSuccess, (state, n) => ({
    ...state,
    resources: n.resources || []
  }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action);
}
