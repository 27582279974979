import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map, switchMap } from 'rxjs';

import * as AuthActions from './auth.actions';
import * as AuthFeature from './auth.reducer';
import { AuthUserService } from './auth.service';

@Injectable()
export class AuthEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.initAuth),
      fetch({
        run: () => {
          // Your custom service 'load' logic goes here. For now just return a success action...
          return this.authUser.getSelf()
            .pipe(
              map((d) => {
                return AuthActions.loadAuthSuccess({ user: d });
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return AuthActions.loadAuthFailure({ error });
        },
      })
    )
  );

  permissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.initAuthPermissions),
      fetch({
        run: () => {
          // Your custom service 'load' logic goes here. For now just return a success action...
          return this.authUser.getSelfResources()
            .pipe(
              map((d) => {
                return AuthActions.loadAuthPermissionsSuccess({ resources: d.resources });
              })
            )
        },
        onError: (action, error) => {
          console.error('Error', error);
          return AuthActions.loadAuthPermissionsFailure({ error });
        },
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authUser: AuthUserService
  ) { }
}
