export interface Organization extends Record<string, any> {
  id: string;
  name: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  ein: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface RecurlyResponse extends Organization {
  recurly_id: string;
  username: string;
  password: string;
  message?: string;
}

export interface A0Organization {
  display_name: string;
  id: string;
  name: string;
}
