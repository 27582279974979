import * as _ from 'lodash';

export const parseFullName = (node: Record<string, any>): string => {
  const firstName = _.get(node, 'firstName');
  let middleName = _.get(node, 'middleName');
  const lastName = _.get(node, 'lastName');
  if (_.isEmpty(firstName) || _.isEmpty(lastName)) {
    return '';
  }

  if (_.isEmpty(middleName) || _.isNil(middleName)) {
    middleName = ' ';
  } else {
    middleName = ` ${middleName} `
  }

  return `${_.upperFirst(firstName)}${_.upperFirst(middleName)}${_.upperFirst(lastName)}`;
}
