import moment from 'moment';
import _ from 'lodash';
import { ValueFormatterFunc } from '@ag-grid-community/core';

export const dateFormatter: ValueFormatterFunc<any> = (params: { value: string } | undefined) => {
  if (params && params.value) {
    const date = moment(params.value);
    if (date.isValid()) {
      return date.format('MM/DD/YYYY hh:mm:ss A');
    }
    return params.value;
  }
  return _.get(params, 'value') as string;
};
