import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, TemplateRole } from '@ui/view-models';
import { Template, TemplateTag, TemplateContext, SelectedTemplateRole } from '@webviewer/types';
import qs from 'qs';
import { map, Observable, switchMap, tap } from 'rxjs';

interface TemplateRolePayload {
  name: string;
  description: string;
}

interface UserTemplate {
  userId: string;
  template: {
    id: string
    name: string
  }
}

interface TemplatePayload {
  name: string;
  type: string;
  numPages: number;
  filename: string;
  gcsRefId: string;
  templateTags: Array<TemplateTag & { templateRoleId?: string }>;
  dynamicFieldTags: Array<TemplateTag & { fieldId: string; name: string }>;
  selectedTemplateRoles?: readonly SelectedTemplateRole[];
}

interface RequestIframeUrlPayload {
  userId: string;
  organizationId: string;
  application: 'create-new-template' |
    'organization-templates' |
    'template-roles' |
    'apply-template' |
    'edit-template';
}

interface ResponseIframeUrl {
  data: string;
  url: string;
  host: string;
}

@Injectable({
  providedIn: 'root',
})
export class TemplatingService {
  constructor(private http: HttpClient) {
  }

  getUserPermissions() {
    return this.http.get<{ resources: Array<string> }>('/api/user/resources');
  }
  // get user templates
  getTemplateRoles(params: any = {}) {
    const q = qs.stringify({ ...params });
    return this.http.get<{ count: number, data: Array<TemplateRole> }>(`/api/templating/template-roles?${q}`)
  }

  getTemplateContext() {
    return this.http.get<{ data: TemplateContext }>('/api/templating/context');
  }

  getHomepageTemplateRole() {
    return this.http.get<{ data: TemplateRole[] }>(`/api/templating/template-roles/home`)
  }

  // get user templates
  getIframeUrl(params: RequestIframeUrlPayload) {
    return this.http.post<ResponseIframeUrl>(`/api/iframe-url`, params);
  }

  updateTemplateRole(id: string, body: Partial<TemplateRolePayload>) {
    return this.http.patch<{ data: TemplateRolePayload }>(`/api/templating/template-roles/${id}`, body)
  }

  addTemplateRole(body: TemplateRolePayload[]) {
    return this.http.post<{ data: TemplateRolePayload[] }>(`/api/templating/template-roles`, body)
  }

  deleteTemplateRoles(body: { ids: string[] }) {
    return this.http.delete<{ data: number }>(`/api/templating/template-roles`, {
      body
    });
  }

  getTemplate(id: string) {
    return this.http.get<{ data: Template }>(`/api/templating/${id}`);
  }

  // create a template
  createTemplate(body: TemplatePayload) {
    return this.http.post<{ data: UserTemplate }>(`/api/templating/organization-templates`, body);
  }

  // get user templates
  updateTemplate(id: string, body: any = {}) {
    return this.http.patch<{ data: UserTemplate }>(`/api/templating/${id}`, body)
  }

  // get user templates
  getUserTemplates(params: any = {}) {
    const q = qs.stringify({ ...params });
    return this.http.get<{ count: number, data: Array<UserTemplate> }>(`/api/templating/user-templates?${q}`)
  }

  // get organizations templates
  getOrganizationTemplates(params: any = {}) {
    const q = qs.stringify({ ...params });
    return this.http.get<{ count: number, data: Array<Template> }>(`/api/templating/organization-templates?${q}`)
  }

  // delete many organizations templates
  deleteOrganizationTemplates(body: { ids: string[] }) {
    return this.http.delete<{ data: number }>(`/api/templating/organization-templates`, {
      body
    })
  }

  // update organization-templates
  updateOrganizationTemplates(id: string, body: any = {}) {
    return this.http.patch<{ data: TemplatePayload }>(`/api/templating/organization-templates/${id}`, body)
  }

  // update organization-templates
  createOrganizationTemplateRevision(id: string, body: any = {}) {
    return this.http.post<{ data: TemplatePayload }>(`/api/templating/organization-templates/${id}/revision`, body);
  }

  // get colors
  getColors() {
    return this.http.get<{ data: Array<[number, number, number, number]> }>(`/api/tag-svg/colors`)
  }

  // get GCS signed upload url
  getUploadUrl(filename: string, contentType = 'application/pdf', documentId: string) {
    const q = qs.stringify({ filename, contentType, documentId });
    return this.http.get<{ url: string, id: string, gcsRefId: string, filename: string }>(`/api/templating/upload-url?${q}`);
  }

  // upload file to GCS via signed url
  uploadToGcs(url: string, file: File) {
    return this.http.put(url, file, {
      reportProgress: true,
      observe: 'events',
      headers: {
        'content-type': file.type,
      }
    });
  }

  handleTemplateRemap(templateId:string, body: any) {
    return this.http.post<{ data: any }>(`/api/template/${templateId}/remap`, body);
  }


  // NOTE: We updated this to send the document ID to GCS so we use the same ID as the one generated in the document viewer to avoid issues with referencing wrong document IDs.
  handleUpload(file: File, docId: string, onProgress: (progress: number, status: 'in-progress' | 'completed') => void) {
    return this.getUploadUrl(file.name, file.type, docId)
      .pipe(
        switchMap((f) => {
          return new Observable<any>((obs) => {
            this.uploadToGcs(f.url, file)
              .pipe(
                tap((event) => {
                  if (event.type === HttpEventType.UploadProgress) {
                    const progress = Math.round(100 * (event.loaded / (event.total as number)));
                    if (progress < 100) {
                      onProgress(progress, 'in-progress');
                    } else {
                      onProgress(progress, 'completed');
                    }
                  }
                }),
              )
              .subscribe((val) => {
                if ((val as any).status) {
                  obs.next(val);
                  obs.complete();
                }
              })
          }).pipe(
            switchMap((ff) => {
              return this.getReadUrl(f.gcsRefId)
                .pipe(
                  map((urlData) => {
                    return {
                      ...f,
                      ...urlData,
                    }
                  })
                )
            }),
          )
        }),
      )
  }

  // get GCS signed download url
  getReadUrl(gcsRefId: string) {
    const q = qs.stringify({ gcsRefId });
    return this.http.get<{ url: string, gcsRefId: string }>(`/api/templating/signed-url?${q}`)
  }
}
