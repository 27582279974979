import { User } from "@ui/view-models";

export interface AuthUser extends User {
  id: string;
  email: string;
  organizationId: string;
  roleId: string;
  firstName: string;
  lastName: string;
  roles: Array<string>;
  resources: Array<string>;
}
