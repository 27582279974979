export interface SigningOrder {
  id: string;
  status: string;
  organizationId: string;
  titleUserId: string;
  borrowerId: null | string;
  coBorrowerId: null | string;
  sellerId: null | string;
  coSellerId: null | string;
  propertyAddressId: string;
  signingAddressId: string;
  lenderId: string;
  originId: null | string;
  externalUserId: null | string;
  externalAltUserId: null | string;
  branchId: null | string;
  altBranchId: null | string;
  titleId: null | string;
  altTitleId: null | string;
  agencyId: null | string;
  closingType: string;
  documentDeliveryType: string;
  closingDate: Date;
  originatorOrderId: string;
  originatorFileId: string;
  minPayAmount: number;
  maxPayAmount: number;
  scanbacksRequired: boolean;
  createdAt: Date;
  documents: Document[];
  notes: Note[];
  titleUser: TitleUser;
  borrower: TitleUser | null;
  coBorrower: TitleUser | null;
  seller: TitleUser | null;
  coSeller: TitleUser | null;
  propertyAddress: Address;
  signingAddress: Address;
  lender: Lender;
  origin: null | string;
  ronStatus: null | string;
}

export interface TitleUser {
  id: string;
  firstName: string;
  lastName: string;
  type: string;
  email: null | string;
  addressId: null | string;
  company: null | string;
  clientCompanyName: null | string;
  phone: null | string;
  homePhone: null | string;
  workPhone: null | string;
  cellPhone: null | string;
  address: Address | null;
}

export interface Address {
  id: string;
  address: string;
  address2: null | string;
  city: string;
  state: string;
  zip: string;
  name: null | string;
  country: string;
  county: null | string;
}

export interface Document {
  id: string;
  filename: string;
  gcsRefId: string;
  note: string;
  fileType: string;
  signingOrderId: string;
  createdAt: Date;
}

export interface Lender {
  id: string;
  firstName: null | string;
  lastName: null | string;
  addressId: null | string;
  companyName: string;
  phone: null | string;
  email: null | string;
  website: null | string;
  fax: null | string;
  signingOrderId: null | string;
  address: Address | null;
  notes: any[];
}

export interface Note {
  id: string;
  name: null | string;
  lenderId: null | string;
  text: string;
  signingOrderId: string;
  createdAt: Date;
}
