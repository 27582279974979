export interface Invoice extends Record<string, any> {
  id: string;
  description?: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  subtotal: number;
  total: number;
  tax_percent: number;
  tax: number;
  application_fee_amount: number;
  attempted: boolean;
  created: number;
  statement_descriptor: string;
  updated_at: Date;
  customer_name: string;
  customer_email: string;
  status: string;
  collection_method: string;
  hosted_invoice_url?: string;
}

export interface InvoicePayload {
  email: string;
  description: string;
  charges: Array<{ id: number, description: string, amount: number }>
}
