import { isFunction } from 'lodash';
import { EnlWebviewerInstance } from "@webviewer/types"

export const handleSignerChange = async (instance: EnlWebviewerInstance, annots: Annotations.Annotation[], newSignerId: string) => {
  for (const annot of annots) {
    annot.setCustomData('signerId', newSignerId);

    if (isFunction((annot as any).getTagData)) {
      const tagData = await (annot as any).getTagData();

      // Updating tag custom data...
      annot.setCustomData('color', tagData.color);
      annot.setCustomData('fullName', tagData.fullName);
      annot.setCustomData('dataUrl', tagData.dataUrl);
      annot.setCustomData('url', tagData.url);
      annot.setCustomData('index', tagData.index);

      if (isFunction((annot as any).setImageData)) {
        await (annot as any).setImageData(tagData.dataUrl || tagData.url, true);
      }

      if (isFunction((annot as any).setContents) && tagData.text) {
        await (annot as any).setContents(tagData.text);
      }

      const [R, G, B, A] = tagData.color;
      (annot as any).FillColor = new instance.Annotations.Color(R, G, B, A);
      (annot as any).StrokeColor = new instance.Annotations.Color(R, G, B, A);
    }
  }

  instance.annotManager.drawAnnotationsFromList(annots);

  if (annots.length > 0) {
    instance.annotManager.trigger('annotationChanged', [annots, 'modify', { imported: false }])
  }
}