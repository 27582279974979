import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subscription, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserResourcesService implements OnDestroy {
  private userResourcesSubject = new ReplaySubject<Array<string>>(1);
  private userResources$ = this.userResourcesSubject.asObservable();

  subscriptions: Subscription[] = [];

  constructor(private http: HttpClient) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Initializes a ReplaySubject that contains the current user's resources. After calling this
   * method, any component can get the user resources by subscribing to the associated observable
   * through `getUserResources()` without having to do the HTTP request again.
   *
   * @param {string} url User resources endpoint. Most of the time this won't change, but there are
   * cases like the Markup Room where a different endpoint URL is used.
   */
  requestUserResources(url: string = '/api/user/resources') {
    const userResourcesReqSub = this.http.get<{ resources: Array<string> }>(url)
      .pipe(
        catchError(() => of({ resources: [] })),
      )
      .subscribe((data) => {
        if (data) {
          this.userResourcesSubject.next(data.resources);
        }
      }
    );

    this.subscriptions.push(userResourcesReqSub);
  }

  /**
   * This method returns an observable so we can subscribe to the user resources ReplaySubject
   * that was initialized through `requestUserResources()`. Components can use this observable to
   * get the user resources without having to do another HTTP request.
   */
  getUserResources() {
    return this.userResources$;
  }
}
