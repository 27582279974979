export interface Account extends Record<string, any> {
  id?: string | null;
  /**
   * Object type
   */
  object?: string | null;
  /**
   * Accounts can be either active or inactive.
   */
  state?: string | null;
  /**
   * The unique token for automatically logging the account in to the hosted management pages. You may automatically log the user into their hosted management pages by directing the user to: `https://{subdomain}.recurly.com/account/{hosted_login_token}`.
   */
  hostedLoginToken?: string | null;
  /**
   * Indicates if the account has a subscription that is either active, canceled, future, or paused.
   */
  hasLiveSubscription?: boolean | null;
  /**
   * Indicates if the account has an active subscription.
   */
  hasActiveSubscription?: boolean | null;
  /**
   * Indicates if the account has a future subscription.
   */
  hasFutureSubscription?: boolean | null;
  /**
   * Indicates if the account has a canceled subscription.
   */
  hasCanceledSubscription?: boolean | null;
  /**
   * Indicates if the account has a paused subscription.
   */
  hasPausedSubscription?: boolean | null;
  /**
   * Indicates if the account has a past due invoice.
   */
  hasPastDueInvoice?: boolean | null;
  /**
   * When the account was created.
   */
  createdAt?: Date | null;
  /**
   * When the account was last changed.
   */
  updatedAt?: Date | null;
  /**
   * If present, when the account was last marked inactive.
   */
  deletedAt?: Date | null;
  /**
   * The unique identifier of the account. This cannot be changed once the account is created.
   */
  code?: string | null;
  /**
   * A secondary value for the account.
   */
  username?: string | null;
  /**
   * The email address used for communicating with this customer. The customer will also use this email address to log into your hosted account management pages. This value does not need to be unique.
   */
  email?: string | null;
  /**
   * Used to determine the language and locale of emails sent on behalf of the merchant to the customer.
   */
  preferredLocale?: string | null;
  /**
   * Additional email address that should receive account correspondence. These should be separated only by commas. These CC emails will receive all emails that the `email` field also receives.
   */
  ccEmails?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  /**
   * The VAT number of the account (to avoid having the VAT applied). This is only used for manually collected invoices.
   */
  vatNumber?: string | null;
  /**
   * The tax status of the account. `true` exempts tax on the account, `false` applies tax on the account.
   */
  taxExempt?: boolean | null;
  /**
   * The tax exemption certificate number for the account. If the merchant has an integration for the Vertex tax provider, this optional value will be sent in any tax calculation requests for the account.
   */
  exemptionCertificate?: string | null;
  /**
   * The UUID of the parent account associated with this account.
   */
  parentAccountId?: string | null;
  /**
   * An enumerable describing the billing behavior of the account, specifically whether the account is self-paying or will rely on the parent account to pay.
   */
  billTo?: string | null;
  /**
   * Unique ID to identify a dunning campaign. Available when the Dunning Campaigns feature is enabled. Used to specify if a non-default dunning campaign should be assigned to this account. For sites without multiple dunning campaigns enabled, the default dunning campaign will always be used.
   */
  dunningCampaignId?: string | null;

  planId: string;
  planCode: string;
}
