import { Notary } from './notary.model';

export interface User extends Record<string, any> {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  organizationId?: string;
  notary?: Notary;
  createdAt: Date;
  updatedAt: Date;
}

