import {
  APP_INITIALIZER,
  Inject,
  Injectable,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
} from '@angular/core';
import _ from 'lodash';
import { FirebaseOptions } from '@angular/fire/app';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { AuthClientConfig, AuthConfig } from '@auth0/auth0-angular';

export function configInitializer(
  handler: HttpBackend,
  config: AuthClientConfig,
  appConfig: AppClientConfig
) {
  return () => {
    return new HttpClient(handler)
      .get('/api/config?app=organization')
      .toPromise()
      // Set the config that was loaded asynchronously here
      .then((loadedConfig: any) => {
        appConfig.set(loadedConfig);

        return config.set({
          ...loadedConfig.auth0,
          useRefreshTokens: true,
          cacheLocation: 'localstorage',

          redirectUri: `${window.location.origin}/callback?appUrl=${loadedConfig.appUrl || window.location.origin}`,
          httpInterceptor: {
            allowedList: [
              {
                uriMatcher: (uri: string) => {
                  return !_.includes(uri, '/api/user/search-org-by-email');
                  // return false;
                },
              },
            ],
          },
        });
      });
  }
}



export type AppConfigType = Record<string, any>;

export const AppConfigService = new InjectionToken<FirebaseOptions>(
  'app.config'
);

@Injectable({ providedIn: 'root' })
export class AppClientConfig {
  private config?: AppConfigType;

  constructor(@Optional() @Inject(AppConfigService) config?: AppConfigType) {
    if (config) {
      this.set(config);
    }
  }

  /**
   * Sets configuration to be read by other consumers of the service (see usage notes)
   * @param config The configuration to set
   */
  set(config: AppConfigType): void {
    this.config = config;
  }

  /**
   * Gets the config that has been set by other consumers of the service
   */
  get(): AppConfigType {
    return this.config as AppConfigType;
  }
}

@NgModule({
  providers: [],
})
export class AppConfigModule {
  static forRoot(
    config: Partial<AppConfigType>
  ): ModuleWithProviders<AppConfigModule> {
    return {
      ngModule: AppConfigModule,

      providers: [
        {
          provide: AppConfigService,
          useValue: config,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: configInitializer,
          deps: [HttpBackend, AppClientConfig],
          multi: true,
        },
        AppClientConfig,
      ],
    };
  }
}
